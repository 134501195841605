<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <h2
            class="brand-text mb-0"
            :style="getHrefStyle()"
          >
            {{ partnerData.appHeaderTitle }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`

    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,

      partner,
      partnerData,
      getHrefStyle,
    }
  },
}
</script>

<style>

</style>
